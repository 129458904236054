import productService from '../api';
import {
  SET_LOADING,
  SET_MOUNTHLY_AMOUNT,
  SET_ENTIRE_AMOUNT,
  SET_FREE_AMOUNT,
  SET_PAGINATION_FREE_AMOUNT,
  SET_PUBLISHED_ENTIRE_AMOUNT,
  SET_PAGINATION_AMOUNT,
  SET_PRODUCTS,
  SET_HOME_GALLERY,
  SET_SALES_PRODUCTS,
  SET_MS_CONNECTED,
  DELETE_PRODUCT,
  DELETE_CHECKED_PRODUCTS,
  SET_DASHBOARD_TOTALS,
  SET_DASHBOARD_MOST,
  SET_DASHBOARD_GRAPH,
} from './products.constants';
import { replaceTo } from '@lib/utils';

import { setProductsInCartAC, Snackbar, setAlertAC } from '@entities';

export const getProductsTC = (params) => {
  return async (dispatch, getState) => {
    try {
      dispatch(isLoadingAC(true));

      const data = await productService.getProducts(params);
      
      if (!data) {
        dispatch(isLoadingAC(false));
        return;
      }

      const { rows, count, count_free } = data.data;
      const { userData } = getState();
      const amount = count || 0;
      const pagination = count || 1;
      const free = count_free || 0;

      const corrected_rows = rows.map((item) => {
        const clearKeyWords = replaceTo(item?.key_words, ['&quot;','[\\[\\]" ]',';']) || '';
        item.key_words = clearKeyWords.split(',');
        const clearCompatibility = replaceTo(item?.compatibility_draw,'&quot;','"') || '';
        item.compatibility_draw = JSON.parse(clearCompatibility);

        const clearFormats = replaceTo(item?.illustration_formats,'&quot;','"') || '';
        item.illustration_formats = JSON.parse(clearFormats);

        return item;
      });

      dispatch(setProductsAC(corrected_rows));
      dispatch(setPaginationAmountAC(pagination));
      dispatch(setPublishedEntireAmountAC(amount));
      dispatch(setPaginationFreeAmountAC(free));
      dispatch(setProductsInCartAC({ flag: true, cart: userData.cart }));
      dispatch(isLoadingAC(false));
    } catch (e) {
      console.log('[Error]', e);
    } finally {
      dispatch(isLoadingAC(false));
    }
  };
};

export const getHomeGalleryTC = () => {
  return async (dispatch) => {
    try {
      dispatch(isLoadingAC(true));

      const data = await productService.getHomeGallery();

      if (!data) {
        return;
      }

      const corrected_top = data?.data?.top.map((item) => {
        const clearKeyWords = replaceTo(item?.key_words, ['&quot;','[\\[\\]" ]',';']) || '';
        item.key_words = clearKeyWords.split(',');
        const clearCompatibility = replaceTo(item?.compatibility_draw,'&quot;','"') || '';
        item.compatibility_draw = JSON.parse(clearCompatibility);

        const clearFormats = replaceTo(item?.illustration_formats,'&quot;','"') || '';
        item.illustration_formats = JSON.parse(clearFormats);

        return item;
      });

      const corrected_bottom = data?.data?.bottom.map((item) => {
        const clearKeyWords = replaceTo(item?.key_words, ['&quot;','[\\[\\]" ]',';']) || '';
        item.key_words = clearKeyWords.split(',');
        const clearCompatibility = replaceTo(item?.compatibility_draw,'&quot;','"') || '';
        item.compatibility_draw = JSON.parse(clearCompatibility);

        const clearFormats = replaceTo(item?.illustration_formats,'&quot;','"') || '';
        item.illustration_formats = JSON.parse(clearFormats);

        return item;
      });
      
      dispatch(setHomeGalleryAC({top: corrected_top, bottom: corrected_bottom}))
   } catch (e) {
      console.log('[ERROR]', e);
    } finally {
      dispatch(isLoadingAC(false));
    }
  }
}

export const getProductsAmountTC = (from, type) => {
  return async (dispatch) => {
    try {
      let options = {};

      if (from) {
        options = { from };
      }

      const data = await productService.getPublicAmount(options);

      if (!data) {
        return;
      }

      switch (type) {
        case SET_MOUNTHLY_AMOUNT:
          dispatch(setMonthlyAmountAC(data?.data?.latest_count || 0));
          break;

        case SET_ENTIRE_AMOUNT:
          dispatch(setEntireAmountAC(data?.data?.amount || 0));
          break;

        default:
      }
    } catch (e) {
      console.log('[Error]', e);
    }
  };
};

export const getFreeProductsAmountTC = () => {
  return async (dispatch) => {
    try {
      const data = await productService.getFreeAmount();

      if (!data) {
        return;
      }

      dispatch(setFreeAmountAC(data?.data || 0));
    } catch (e) {
      console.log('[Error]', e);
    }
  };
};

export const getAdminProductsAmountTC = () => {
  return async (dispatch) => {
    try {
      const data = await productService.getAmount();

      if (!data) {
        return;
      }

      dispatch(setEntireAmountAC(data?.data?.amount || 0));
    } catch (e) {
      console.log('[Error]', e);
    }
  };
};

export const getDashboardTotalsTC = () => {
  return async (dispatch) => {
    try {
      const data = await productService.getDashboardTotals();

      if (!data) {
        return;
      }

      dispatch(setDashboardMostAC(data?.data?.tops || {}));
      dispatch(setDashboardTotalsAC(data?.data?.totals || {}));
    } catch (e) {
      console.log('[Error]', e);
    }
  };
}

export const getDashboardGraphTC = (param) => {
  return async (dispatch) => {
    try {
      const data = await productService.getDashboardGraph(param);

      if (!data) {
        return;
      }

      const rows = data.data;

      let options = {};
      switch (param.period){
        case 'day': 
          options = {hour: 'numeric', minute: 'numeric'}; 
          break;
        case 'month': 
          options = {month: '2-digit', day: '2-digit'};
          break;
        case 'year': 
          options = {year: 'numeric', month: '2-digit'};
          break;
        case 'total': 
          options = {year: 'numeric', month: '2-digit'};
          break;

        default: options = {};
      }

      const correctRows = rows.map((item) => {
        return {...item, date: new Date(item.date).toLocaleString(navigator.language, options)}
      })

      dispatch(setDashboardGraphAC(correctRows || []));
    } catch (e) {
      console.log('[Error]', e);
    }
  };
}

export const getFreeProductsTC = (options) => {
  return async (dispatch) => {
    try {
      dispatch(isLoadingAC(true));

      const data = await productService.getFreeProducts(options);

      let { rows, count } = data.data;
      
      if (!count) {
        count = 1;
      }

      dispatch(setProductsAC(rows));
      dispatch(setPaginationAmountAC(count));
      dispatch(isLoadingAC(false));
    } catch (e) {
      console.log('[Error]', e);
    } finally {
      dispatch(isLoadingAC(false));
    }
  };
};

export const getFreeTotalsTC = () => {
  return async (dispatch) => {
    try {
      const data = await productService.getFreeTotals();

      if (!data) {
        return;
      }
      
      dispatch(setDashboardTotalsAC(data?.data?.totals || {}));
    } catch (e) {
      console.log('[Error]', e);
    }
  };
}

export const getSalesProductsTC = (params) => {
  return async (dispatch) => {
    try {
      dispatch(isLoadingAC(true));
      const data = await productService.getSalesProducts(params);

      if (!data) {
        return;
      }

      let { rows, count } = data.data;
      
      if (!count) {
        count = 1;
      }

      dispatch(setSalesProductsAC(rows || []));
      dispatch(setPaginationAmountAC(count));
    } catch (e) {
      console.log('[Error]', e);
    } finally {
      dispatch(isLoadingAC(false));
    }
  };
}

export const getArchiveProductTC = (param) => {
  return async (dispatch) => {
    try {
      const data = await productService.getArchive(param);

      const blob = new Blob([new Uint8Array(data.data)], { type: 'application/octet-stream', })
    
      return blob;
    } catch (error) {
      const snackbar = new Snackbar({
        status: 500,
        message: (
          <div className="">
            <h5 className="snack__header">
              Can't download the archive.
            </h5>
          </div>
        ),
    });
    console.log('[ERROR]', error);
    dispatch(setAlertAC(snackbar));
    }
  }
}

export const getFreeProductTC = (params) => {
  return async (dispatch) => {
    try {
      const data = await productService.getFreeProduct(params);

      if(data?.status === 200) {
        const blob = new Blob([new Uint8Array(data.data)], {
          type: 'application/zip',
        });

        return blob;
      }

      throw new Error("The server did not return a result");
    } catch (error) {
      const snackbar = new Snackbar({
        status: 500,
        message: (
          <div className="">
            <h5 className="snack__header">
              Can't download the archive.
            </h5>
          </div>
        ),
    });
    console.log('[ERROR]', error);
    dispatch(setAlertAC(snackbar));
    }
  }
}


export const getReadyProductsTC = (options) => {
  return async (dispatch) => {
    try {
      dispatch(isLoadingAC(true));

      const data = await productService.getReadyProducts(options);

      let { rows, count } = data.data;
      
      if (!count) {
        count = 1;
      }

      dispatch(setProductsAC(rows));
      dispatch(setPaginationAmountAC(count));
      dispatch(isLoadingAC(false));
    } catch (e) {
      console.log('[Error]', e);
    } finally {
      dispatch(isLoadingAC(false));
    }
  };
};

export const updateProductTC = (id, formData) => {
  return async (dispatch) => {
    try {
      await productService.updateProduct(id, formData);

      const snackbar = new Snackbar({
        status: 200,
        message: (
          <div className="">
            <h5 className="snack__header">
              Product update successful
            </h5>
          </div>
        ),
      });
      dispatch(setAlertAC(snackbar));
    } catch (e) {
      const snackbar = new Snackbar({
        status: 500,
        message: (
          <div className="">
            <h5 className="snack__header">
              Product update error
            </h5>
          </div>
        ),
      });
      console.log('[ERROR]', e);
      dispatch(setAlertAC(snackbar));
    };
  };
};

export const updateProductSettingsTC = (id, params) => {
  return async (dispatch) => {
    try {
      const data = await productService.updateProductSettings(id, params);

      if (data?.status === 200) {
        let update = {text: "Product update successfully!", status: 200};

        if (params.hasOwnProperty('isPublic')) { 
          update = params.isPublic ? 
            {text: 'Product was published successfully!', status: 200} :
            {text: 'Product was successfully hidden!', status: 400};
        };

        const snackbar = new Snackbar({
          status: update.status,
          message: (
            <div className="">
              <h5 className="snack__header">
                {update.text}
              </h5>
            </div>
          ),
        });
        dispatch(setAlertAC(snackbar));

        return true;
      };

      return false;
    } catch (e) {
      const snackbar = new Snackbar({
        status: 500,
        message: (
          <div className="">
            <h5 className="snack__header">
              Product update error
            </h5>
          </div>
        ),
      });

      console.log('[ERROR]', e);
      dispatch(setAlertAC(snackbar));
      return false;
    };
  };
};

export const updateProductsSettingsTC = (params) => {
  return async (dispatch) => {
    try {
      await productService.updateProductsSettings(params);
      const snackbar = new Snackbar({
        status: 200,
        message: (
          <div className="">
            <h5 className="snack__header">
              Products update successful
            </h5>
          </div>
        ),
      });
      dispatch(setAlertAC(snackbar));
    } catch (e) {
      const snackbar = new Snackbar({
        status: 500,
        message: (
          <div className="">
            <h5 className="snack__header">
              Products updating error
            </h5>
          </div>
        ),
      });
      console.log('[ERROR]', e);
      dispatch(setAlertAC(snackbar));
    };
  };
};

export const deleteProductTC = (id) => {
  return async (dispatch) => {
    try {
      await productService.deleteProduct(id);
      const snackbar = new Snackbar({
        status: 200,
        message: (
          <div className="">
            <h5 className="snack__header">
              Product delete successful
            </h5>
          </div>
        ),
      });
      dispatch(setAlertAC(snackbar));
      dispatch(deleteProductAC(id));
    } catch (e) {
      const snackbar = new Snackbar({
        status: 500,
        message: (
          <div className="">
            <h5 className="snack__header">
              Product delete error
            </h5>
          </div>
        ),
      });
      console.log('[ERROR]', e);
      dispatch(setAlertAC(snackbar));
    };
  };
};

export const deleteProductsTC = (ids) => {
  return async (dispatch) => {
    try {
      await productService.deleteProducts(ids);
      const snackbar = new Snackbar({
        status: 200,
        message: (
          <div className="">
            <h5 className="snack__header">
              Products delete successful
            </h5>
          </div>
        ),
      });
      dispatch(setAlertAC(snackbar));
      dispatch(deleteCheckedProductsAC(ids));
    } catch (e) {
      const snackbar = new Snackbar({
        status: 500,
        message: (
          <div className="">
            <h5 className="snack__header">
              Products deleting error
            </h5>
          </div>
        ),
      });
      console.log('[ERROR]', e);
      dispatch(setAlertAC(snackbar));
    };
  };
};

//ACTIONS
export const isLoadingAC = (data) => {
  return {
    type: SET_LOADING,
    payload: data,
  };
};

export const setProductsAC = (data) => {
  return {
    type: SET_PRODUCTS,
    payload: data,
  };
};

export const setHomeGalleryAC = (data) => {
  return {
    type: SET_HOME_GALLERY,
    payload: data,
  };
};

export const setSalesProductsAC = (data) => {
  return {
    type: SET_SALES_PRODUCTS,
    payload: data,
  };
};

export const deleteProductAC = (id) => {
  return {
    type: DELETE_PRODUCT,
    payload: id,
  };
};

export const deleteCheckedProductsAC = (ids) => {
  return {
    type: DELETE_CHECKED_PRODUCTS,
    payload: ids,
  };
};

export const setMonthlyAmountAC = (amount) => {
  return {
    type: SET_MOUNTHLY_AMOUNT,
    payload: amount,
  };
};

export const setEntireAmountAC = (amount) => {
  return {
    type: SET_ENTIRE_AMOUNT,
    payload: amount,
  };
};

export const setFreeAmountAC = (amount) => {
  return {
    type: SET_FREE_AMOUNT,
    payload: amount,
  };
};

export const setPaginationFreeAmountAC = (amount) => {
  return {
    type: SET_PAGINATION_FREE_AMOUNT,
    payload: amount,
  };
};

export const setPublishedEntireAmountAC = (amount) => {
  return {
    type: SET_PUBLISHED_ENTIRE_AMOUNT,
    payload: amount,
  };
};

export const setPaginationAmountAC = (amount) => {
  return {
    type: SET_PAGINATION_AMOUNT,
    payload: amount,
  };
};

export const setMsConnectedAC = (flag) => {
  return {
    type: SET_MS_CONNECTED,
    payload: flag,
  };
};

export const setDashboardTotalsAC = (payload) => {
  return {
    type: SET_DASHBOARD_TOTALS,
    payload: payload,
  };
};

export const setDashboardMostAC = (payload) => {
  return {
    type: SET_DASHBOARD_MOST,
    payload: payload,
  };
};

export const setDashboardGraphAC = (payload) => {
  return {
    type: SET_DASHBOARD_GRAPH,
    payload: payload,
  };
};
