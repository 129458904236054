import { createSelector } from 'reselect';

export const getSettings = (state) => state.settingsData;

export const getHourlyrateValue = createSelector(
  getSettings,
  (settingsData) => settingsData.hourlyRate
);

export const getReviews = createSelector(
  getSettings,
  (settingsData) => settingsData.reviews
);
