import React, { useState, useEffect } from "react";
import { Popover } from 'antd';
import "./free.scss";

import { Text, CheckBox } from "@ui-kit";
import { ModalImage } from "@features";

const FreeItem = ({
  item = {},
  checkedItems = [],
  setCheckedItems = () => {},
}) => {
  const [isItemCheck, setItemCheck] = useState();
  const { 
    pathToBrowse = '', 
    pathToPreview = '',
    title = '',
    id = 0,
    wentFreeAt = '-',
    rate_type = 'Extended commercial',
    amount_downloaded_free= 0,
    amount_views_free = 0,
    isPromoted = 0,
    isFree = 0,
  } = item;

  const freeFromDate = wentFreeAt ? new Date(wentFreeAt).toLocaleString(undefined, {
    year: 'numeric', 
    month: '2-digit', 
    day: '2-digit'
  }) : '-';
  const freeFromTime = wentFreeAt ? new Date(wentFreeAt).toLocaleTimeString(undefined, {
    hour: 'numeric', 
    minute: 'numeric',
    second: 'numeric',
  }) : '-';

  const PRODUCT_URL = process.env.REACT_APP_PRODUCTS_URL;
  const isChecked = checkedItems.filter((item) => item.id === id);

  useEffect(() => {
    setItemCheck(!!isChecked.length);
    // eslint-disable-next-line
  }, [checkedItems]);

  const setCheck = (event) => {
    const value = event.target.checked;
    setItemCheck(value);

    if (value) {
      setCheckedItems([...checkedItems, item]);
    } else {
      const newChecked = checkedItems.filter((item) => item.id !== id);
      setCheckedItems(newChecked);
    }
  };

  const renderStatus = () => {
    const statusList = []
    if (+isPromoted) statusList.push('Promoted');
    if (+isFree) statusList.push('Free');
    if (!statusList.length || !+isFree) statusList.push(rate_type || 'Extended commercial');

    return statusList.join(', ');
  }

  return (
    <div className="admin__table-row">
      <div className="product-name d-flex ai-center">
        <CheckBox
          className="checkbox__item"
          checked={isItemCheck}
          onChange={setCheck}
        />
        <ModalImage
          key={`img-${id}`}
          src={`${PRODUCT_URL}/${pathToBrowse}`}
          backgroundSrc={`${PRODUCT_URL}/${pathToPreview}`}
        />

        <div className="product-title">
        <Popover
            overlayStyle={{overflow: 'initial'}}
            overlayClassName="product-title__pop"
            content={<Text as="h5">{title || ''}</Text>}
            trigger={'hover'}
            arrow={true}
            placement={'topLeft'}
          >
            <>
              <Text as="h5">{title || ''}</Text>
            </>
          </Popover>
          <Text as="h6">#{id}</Text>
        </div>
      </div>

      <div className="product-date">
        <Text as="h5">{freeFromDate || ''}</Text>
        <Text as="h6">{freeFromTime || ''}</Text>
      </div>

      <div className="product-status">
        <Text as="h6">{renderStatus()}</Text>
      </div>

      <div className="product-views">
        <Text as="h6">{amount_views_free}</Text>
      </div>
      
      <div className="product-downloads">
        <Text as="h6">{amount_downloaded_free}</Text>
      </div>
    </div>
  );
};

export default FreeItem;
