import React, { useState, useEffect, useRef } from "react";
import { Carousel } from "antd";
import { useSelector } from "react-redux";
import "./reviews.scss";

import { Text, Icon, Link } from "@ui-kit";
import { getReviews } from "@entities";
import Star from "@ui-kit/icons/star-accent700.svg";

const Reviews = () => {
  const reviews = useSelector(getReviews);
  const [slidesToShow, setSlidesToShow] = useState(1);

  const updateSlidesToShow = () => {
    const width = window.innerWidth;
    if (width < 606) {
      setSlidesToShow(1);
    } else if (width < 1000) {
      setSlidesToShow(2);
    } else {
      setSlidesToShow(3);
    }
  };

  useEffect(() => {
    updateSlidesToShow();
    window.addEventListener("resize", updateSlidesToShow);

    return () => {
      window.removeEventListener("resize", updateSlidesToShow);
    };
  }, []);

  const renderDate = (itemDate) => {
    const date = new Date(itemDate);
    const options = {
      day: "2-digit",
      month: "long",
      year: "numeric",
    };

    return date.toLocaleString("en-US", options);
  };

  return (
    <div className="reviews__container">
      <div className="main__container reviews__content">
        <div className="reviews__header">
          <Text as="h1">Our reviews</Text>
          <Text as="h3">
            Read honest reviews from people who have already ordered custom
            illustrations from us
          </Text>
        </div>
        <div className="reviews__items">
          <Carousel
            autoplay
            slidesToShow={slidesToShow}
            arrows
            //infinite={true}
            draggable={true}
          >
            {reviews.map((item, index) => {
              return (
                <div className="reviews__item" key={`item-${index}`}>
                  <Text as="h5" className="date mb-20">
                    {renderDate(item.date)}
                  </Text>
                  <div className="stars-rating mb-20">
                    <StarRating rating={item.stars} />
                  </div>
                  <div>
                    <Link 
                        to={item.link} 
                        theme="text" 
                        size="md" 
                        className="title mb-10"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {item.title}
                    </Link>
                  </div>

                  <TruncatedText text={item.text} link={item.link} />

                  <div className="user-info">
                    <div className="avatar-label__container d-flex ai-center">
                      <div className="avatar__wrapper d-flex jc-center ai-center pr xl">
                        <div className="avatar__img-wrapper">
                          <Icon src={item.user.avaStatic} alt="avatar" />
                        </div>
                      </div>
                      <div className="author__wrapper">
                        <Text as="h6" className="author__name">
                          <Text as="span" className="mr-8">
                            {item.user.firstName}
                          </Text>
                        </Text>
                        <Text as="h6" className="author__text">
                          {item.user.role}
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

const StarRating = ({ rating }) => {
  const clampedRating = Math.min(5, Math.max(1, rating));

  return (
    <div className="stars__container">
      {[...Array(5)].map((_, index) => (
        <span
          key={index}
          style={{ display: index >= clampedRating && "none" }}
          className="star"
        >
          <Icon src={Star} />
        </span>
      ))}
    </div>
  );
};

const TruncatedText = ({ text, link = "#" }) => {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const textRef = useRef(null);
  
    useEffect(() => {
        if (!textRef.current) return;

        const checkOverflow = () => {
          const element = textRef.current;
          if (!element) return;
    
          const isTruncated = 
            element.scrollHeight > element.clientHeight || 
            element.offsetHeight < element.scrollHeight;
    
          setIsOverflowing(isTruncated);
        };
    
        const resizeObserver = new ResizeObserver(checkOverflow);
        resizeObserver.observe(textRef.current);
    
        const timeoutId = setTimeout(checkOverflow, 100);
    
        return () => {
          resizeObserver.disconnect();
          clearTimeout(timeoutId);
        };
    }, [text]);
  
    return (
      <div className="text-container mb-20">
        <div 
          ref={textRef}
          className="truncated-text"
          style={{ '--max-lines': 13 }}
        >
          {text}
        </div>
        {isOverflowing && (
          <div className="view-more-wrapper">
            <Link
              to={link} 
              theme="text"
              target="_blank"
              rel="noreferrer"
            >
              view more
            </Link>
          </div>
        )}
      </div>
    );
  };

export default Reviews;
