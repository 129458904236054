import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import "./dashboard.scss";

import { EmptyProducts, TableSalesProducts } from "@widgets";
import { Text, Link } from "@ui-kit";
import { 
  getDashboardTotals,
  getDashboardMost,
  getIsLoading,
} from "@entities";
import TotalChart from "./totalChart";
import SalesChart from "./salesChart";
import MostTables from "./mostTables";
import { ADMIN_MY_SALES_URL } from "@app/routes";

const ProductsDashboard = ({ sales = [] }) => {
  const isLoading = useSelector(getIsLoading);
  const totalChart = useSelector(getDashboardTotals);
  const mostTables = useSelector(getDashboardMost);
  const [forceRender, setForceRender] = useState(true);

  const isEmpty = !sales?.length 
    && (!totalChart || JSON.stringify(totalChart) === '{}') 
    && (!mostTables || JSON.stringify(mostTables) === '{}');
  const today = new Date();
  const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = oneMonthAgo.toLocaleString(navigator.language, options); 

  const followingData = {startDate: oneMonthAgo, endDate: today};
  
  useEffect(() => {
    if (isLoading) {
      return setForceRender(true);
    }

    setTimeout(() => setForceRender(false), 1000);
  }, [isLoading]);

  const renderDashboard = () => {
    if (isEmpty && !forceRender) return <EmptyProducts />;

    return (
      <>
        <TotalChart items={totalChart} isDashboard={true} />
        <SalesChart />
        <MostTables data={mostTables} />
        <div className="dashboard__table-sales">
          <div className="title">
            <Text as="h4">Recent sales</Text>
            <Text as="h6">{formattedDate} - Today</Text>
          </div>
          <TableSalesProducts sales={sales} followingData={followingData} />
          <div className="dashboard__sales-action">
            <Link to={ADMIN_MY_SALES_URL}>View all sales</Link>
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="dashboard__container">
      {renderDashboard()}
    </div>
  );
};

export default ProductsDashboard;
