import settingsService from '../api';
import { SET_SETTINGS, SET_REVIEWS } from './settings.constants';


export const getSettingsTC = () => {
  return async (dispatch) => {
    try {
      const resp = await settingsService.getSettings();

      if (!resp?.data) {
        return;
      }

      dispatch(setSettingsAC(resp.data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getReviewsTC = () => {
  return async (dispatch) => {
    try {
      const response = await settingsService.getReviews();

      if (!response?.data) {
        return;
      }

      dispatch(setSettingsAC(response.data));
    } catch (error) {
      console.log(error);
    }
  }
}

//ACTIONS
export const setSettingsAC = (payload) => {
  return {
    type: SET_SETTINGS,
    payload,
  };
};

export const setReviewsAC = (payload) => {
  return {
    type: SET_REVIEWS,
    payload,
  };
};
