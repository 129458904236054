export const HASH_MAIN_CONTENT              = "#main__content";
export const BASE_URL                       = { pathname: '/'};
export const CHECKOUT_PROJECT_URL           = { pathname:'/checkout/projects/:id'};
export const CART_URL                       = { pathname:'/cart', hash: HASH_MAIN_CONTENT};
export const BROWSE_URL                     = { pathname:'/gallery', hash: HASH_MAIN_CONTENT};
export const SUPPORT_URL                    = { pathname:'/support', hash: HASH_MAIN_CONTENT};
export const ABOUT_US_URL                   = { pathname:'/about-us', hash: HASH_MAIN_CONTENT};
export const CHECKOUT_URL                   = { pathname:'/checkout', hash: HASH_MAIN_CONTENT};
export const LICENSES_URL                   = { pathname:'/licenses', hash: HASH_MAIN_CONTENT};
export const CONTACTS_URL                   = { pathname:'/contact-us', hash: HASH_MAIN_CONTENT};
export const RETURN_POLICY_URL              = { pathname:'/return-policy', hash: HASH_MAIN_CONTENT};
export const COOKIE_POLICY_URL              = { pathname:'/cookie-policy', hash: HASH_MAIN_CONTENT};
export const PRIVACY_POLICY_URL             = { pathname:'/privacy-policy', hash: HASH_MAIN_CONTENT};
export const TERMS_CONDITIONS_URL           = { pathname:'/terms-conditions', hash: HASH_MAIN_CONTENT};
export const JOB_APPLICATION_URL            = { pathname:'/job-application', hash: HASH_MAIN_CONTENT};

export const BROWSE_ITEM_URL                = { pathname: BROWSE_URL.pathname.concat('/:id'), hash: HASH_MAIN_CONTENT};
export const BROWSE_SEARCH_URL              = { pathname: BROWSE_URL.pathname/*, hash: HASH_MAIN_CONTENT, search:`?orderBy=popular&limit=80&page=1&scale=${document.body.clientWidth}`*/};
export const PAYMENT_STATUS_URL             = { pathname: CHECKOUT_URL.pathname.concat('/status'), hash: HASH_MAIN_CONTENT};
export const PAYMENT_PROJECT_STATUS_URL     = { pathname: CHECKOUT_PROJECT_URL.pathname.concat('/status'), hash: HASH_MAIN_CONTENT};

export const TAROTTALES_URL                 = { pathname: '/tarottales', hash: HASH_MAIN_CONTENT};
export const TAROTTALES_PRIVACY_POLICY_URL  = { pathname: TAROTTALES_URL.pathname.concat('/privacy-policy'), hash: HASH_MAIN_CONTENT};
export const TAROTTALES_TERMS_CONDITIONS_URL= { pathname: TAROTTALES_URL.pathname.concat('/terms-and-conditions'), hash: HASH_MAIN_CONTENT};
export const TAROTTALES_LICENSE_SOUND_URL   = { pathname: TAROTTALES_URL.pathname.concat('/license_sound'), hash: HASH_MAIN_CONTENT};

export const CAREER_URL                     = { pathname:'/career', hash: HASH_MAIN_CONTENT};
export const VACANCY_THEME_MAKER_URL        = { pathname: CAREER_URL.pathname.concat('/theme-maker'), hash: HASH_MAIN_CONTENT}
export const VACANCY_ILLUSTRATOR_URL        = { pathname: CAREER_URL.pathname.concat('/illustrator'), hash: HASH_MAIN_CONTENT}
export const VACANCY_SOFTWARE_TESTER_URL    = { pathname: CAREER_URL.pathname.concat('/software-tester'), hash: HASH_MAIN_CONTENT}
export const VACANCY_PROJECT_MANAGER_URL    = { pathname: CAREER_URL.pathname.concat('/project-manager'), hash: HASH_MAIN_CONTENT}
export const VACANCY_ATTRIBUTOR_URL         = { pathname: CAREER_URL.pathname.concat('/attributor'), hash: HASH_MAIN_CONTENT}

export const PROJECTS_URL                   = { pathname:'/projects'};
export const DASHBOARD_URL                  = { pathname:'/dashboard'};
export const PRODUCTS_URL                   = { pathname:'/products'};
export const MY_SALES_URL                   = { pathname:'/my-sales'};
export const PROMOTED_URL                   = { pathname:'/promoted'};
export const FREE_PRODUCTS_URL              = { pathname:'/free'};
export const USERS_URL                      = { pathname:'/users'};
export const MANAGEMENT_SETTINGS_URL        = { pathname:'/management/settings'};
export const RESET_PASS_CONFIRM_URL         = { pathname:'/account/password/confirm/*'};
export const RESET_PASS_REJECT_URL          = { pathname:'/account/password/reject/*'};
export const LOGIN_URL                      = { pathname:'/login', hash: HASH_MAIN_CONTENT};
export const SIGNUP_URL                     = { pathname:'/signup', hash: HASH_MAIN_CONTENT};
export const ARCHIVE_PROJECTS_URL           = { pathname: PROJECTS_URL.pathname.concat('/archive')};
export const ACTIVATED_SUCCESS_URL          = { pathname:'/activate/success', hash: HASH_MAIN_CONTENT};

export const USER_URL                       = { pathname:'/user'};
export const PURCHASES_URL                  = { pathname:'/purchases'};
export const USER_BILLS_URL                 = { pathname: USER_URL.pathname.concat('/bills')};
export const USER_REVIEWS_URL               = { pathname: USER_URL.pathname.concat('/reviews')};
export const USER_SETTINGS_URL              = { pathname: USER_URL.pathname.concat('/settings')};
export const USER_PASSWORD_URL              = { pathname: USER_URL.pathname.concat('/password')};
export const USER_PROJECTS_URL              = { pathname: USER_URL.pathname.concat(PROJECTS_URL.pathname)};

export const CREATE_URL                     = { pathname:'/create'};
 
export const ADMIN_URL                      = { pathname:'/admin', hash: HASH_MAIN_CONTENT};
export const ADMIN_USER_URL                 = { pathname: ADMIN_URL.pathname.concat(USER_URL.pathname), hash: HASH_MAIN_CONTENT};
export const ADMIN_USERS_URL                = { pathname: ADMIN_URL.pathname.concat(USERS_URL.pathname), hash: HASH_MAIN_CONTENT, search: '?page=1&limit=30'};
export const ADMIN_LOGIN_URL                = { pathname: ADMIN_URL.pathname.concat(LOGIN_URL.pathname)};
export const ADMIN_PROJECTS_URL             = { pathname: ADMIN_URL.pathname.concat(PROJECTS_URL.pathname)};
export const ADMIN_DASHBOARD_URL            = { pathname: ADMIN_URL.pathname.concat(DASHBOARD_URL.pathname)};
export const ADMIN_PRODUCTS_URL             = { pathname: ADMIN_URL.pathname.concat(PRODUCTS_URL.pathname), search: '?page=1&limit=80&isPublic=1'};
export const ADMIN_MY_SALES_URL             = { pathname: ADMIN_PRODUCTS_URL.pathname.concat(MY_SALES_URL.pathname)};
export const ADMIN_EDIT_PRODUCT_URL         = { pathname: ADMIN_PRODUCTS_URL.pathname.concat('/:id'), hash: HASH_MAIN_CONTENT};
export const ADMIN_PROMOTED_URL             = { pathname: ADMIN_PRODUCTS_URL.pathname.concat(PROMOTED_URL.pathname), search: '?page=1&limit=80&isPromoted=1'};
export const ADMIN_FREE_PRODUCTS_URL        = { pathname: ADMIN_PRODUCTS_URL.pathname.concat(FREE_PRODUCTS_URL.pathname), search: '?page=1&limit=80'};
export const ADMIN_PURCHASES_URL            = { pathname: ADMIN_URL.pathname.concat(PURCHASES_URL.pathname), search: '?page=1&limit=20&illustrationType=products'};
export const ADMIN_USER_BILLS_URL           = { pathname: ADMIN_URL.pathname.concat(USER_BILLS_URL.pathname), search: '?page=1&limit=20'};
export const ADMIN_USER_REVIEWS_URL         = { pathname: ADMIN_URL.pathname.concat(USER_REVIEWS_URL.pathname)};
export const ADMIN_USER_SETTINGS_URL        = { pathname: ADMIN_URL.pathname.concat(USER_SETTINGS_URL.pathname)};
export const ADMIN_USER_PASSWORD_URL        = { pathname: ADMIN_URL.pathname.concat(USER_PASSWORD_URL.pathname)};
export const ADMIN_USER_PROJECTS_URL        = { pathname: ADMIN_URL.pathname.concat(USER_PROJECTS_URL.pathname)};
export const ADMIN_ARCHIVE_PROJECTS_URL     = { pathname: ADMIN_URL.pathname.concat(ARCHIVE_PROJECTS_URL.pathname), search: '?page=1&limit=30&admin_info_active=chat'};
export const ADMIN_MANAGEMENT_SETTINGS_URL  = { pathname: ADMIN_URL.pathname.concat(MANAGEMENT_SETTINGS_URL.pathname)};
export const ADMIN_PROJECT_CREATE_URL       = { pathname: ADMIN_URL.pathname.concat(PROJECTS_URL.pathname).concat(CREATE_URL.pathname)};

export const NOT_FOUND_URL                  = { pathname: '*'};

 // remove to feature
export const OLD_BROWSE_URL                 = { pathname:'/browse', hash: HASH_MAIN_CONTENT};
export const OLD_BROWSE_ITEM_URL            = { pathname: PRODUCTS_URL.pathname.concat('/:linkId'), };
export const OLD_JOB_APPLICATIONS_URL       = { pathname:'/job_applications', hash: HASH_MAIN_CONTENT};
export const OLD_ADMIN_PROJECTS_URL         = { pathname: ADMIN_URL.pathname.concat('/projects_list/edit/:id')};
export const OLD_ADMIN_USER_PROJECTS_URL    = { pathname: ADMIN_URL.pathname.concat('/projects/:id')};
