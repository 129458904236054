import {
  SET_REVIEWS,
  SET_SETTINGS,
} from './settings.constants';


const initialState = {
  hourlyRate: 0,
  hourlyWithoutLicense: 0,
  hoursNumber: 0, //???
  clientsCount: 0,
  ordersCount: 0,
  ordersProgressCount: 0,
  clientsCountAll: 0,
  ordersCountAll: 0,
  ordersProgressCountAll: 0,
  reviews: [
    {
      date: "02-24-2025",
      stars: 5,
      link: "https://maps.app.goo.gl/qsMD7wG3Djq4MK497",
      title: "MyTeam Art is truly a dream team for any illustration needs!",
      text: "MyTeam Art is truly a dream team for any illustration needs! They have fully mastered the art of bringing words to life, which is a craft that demands not only skill but a profound connection to the narrative's heart. I consider myself incredibly fortunate to have stumbled upon such a treasure trove of talent for my children's picture book. Each page is a testament to their meticulous attention to detail, as if they had a magical lens to see into my imagination, capturing every color, emotion, and character with impeccable precision.",
      user: {
        avaStatic: "/images/social/google.png",
        firstName: "Maxim P.",
      },
    },
    {
      date: "02-25-2025",
      stars: 4,
      link: "https://maps.app.goo.gl/qsMD7wG3Djq4MK497",
      title: "MyTeam Art is truly a dream team for any illustration needs!",
      text: "MyTeam Art is truly a dream team for any illustration needs! They have fully mastered the art of bringing words to life, which is a craft that demands not only skill but a profound connection to the narrative's heart.",
      user: {
        avaStatic: "/images/social/google.png",
        firstName: "Maxim P.",
      },
    },
    {
      date: "03-03-2025",
      stars: 3,
      link: "https://maps.app.goo.gl/qsMD7wG3Djq4MK497",
      title: "MyTeam Art is truly a dream team for any illustration needs!",
      text: "MyTeam Art is truly a dream team for any illustration needs! They have fully mastered the art of bringing words to life, which is a craft that demands not only skill but a profound connection to the narrative's heart. I consider myself incredibly fortunate to have stumbled upon such a treasure trove of talent for my children's picture book. Each page is a testament to their meticulous attention to detail, as if they had a magical lens to see into my imagination, capturing every color, emotion, and character with impeccable precision. The pride that MyTeam Art takes in lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum",
      user: {
        avaStatic: "/images/social/google.png",
        firstName: "Maxim P.",
      },
    }
  ]
};

const userReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case SET_SETTINGS: {
      return {
        ...state,
        ...payload,
      };
    }

    case SET_REVIEWS: {
      return {
        ...state,
        reviews: payload,
      };
    }

    default:
      return state;
  }
};

export default userReducer;
