import "./leave-review.scss";

import { Text, Icon, Link } from "@ui-kit";

const LeaveReview = () => {
    const reviewLink = process.env.REACT_APP_REVIEW_URL;

  return (
    <div className="leave-review__container">
      <div className="main__container leave-review__content d-flex">
        <div className="leave-review_img">
          <Icon src="/images/banner_reviews.png" />
        </div>
        <div className="leave-review__wrapper">
          <Text as="h2">Your feedback is important</Text>
          <Text as="h4">
            We would be very grateful if you could find the time to leave a
            review of your experience using our illustrations or working with
            us. This will help us a lot!
          </Text>
          <Link to={reviewLink} size="lg" target="_blank" rel="noreferrer">
            Leave a review
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LeaveReview;
