import { ApiService } from '@shared/api';

class SettingsService extends ApiService {
  // eslint-disable-next-line
  constructor(props) {
    super(props);

    this.baseUrl = '/settings'; 
  }

  async getSettings() {
    return await this.instance.get(this.baseUrl);
  }

  async getReviews() {
    return await this.instance.get('/reviews');
  }
}

const settingService = new SettingsService();

export default settingService;
